if (!!window.IntersectionObserver) {
    let observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                }
            });
        },
        { rootMargin: '0px -100px -100px 0px' }
    );
    document.querySelectorAll('[data-animate]').forEach((el) => {
        observer.observe(el);
    });
}
