var nobots = document.querySelectorAll('[data-nobots]');

Array.from(nobots).forEach((bot) => {
    if (bot.localName !== 'a') {
        bot = bot.querySelector('a');
    }

    let data = atob(bot.href.split(':')[1]);
    let type = bot.href.split(':')[0];

    bot.href = type + ':' + data.replace(/ /g, '');
    bot.innerText = data;
});

/*
<a data-nobots href="mailto:{{ fn( 'base64_encode', contact.email_address) }}">{{ fn( 'base64_encode', contact.email_address) }}</a>
<a data-nobots  href="tel:{{ fn( 'base64_encode', contact.phone_number ) }}">{{ fn( 'base64_encode', contact.phone_number ) }}</a> <br> 
*/
