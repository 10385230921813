if (!!window.IntersectionObserver) {
    let observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    let id = entry.target.getAttribute('data-instagram-feed');
                    fetch('/wp-json/brickblocks/v1/instagram/1')
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.shortcode) {
                                entry.target.innerHTML = data.shortcode;
                                setTimeout(() => {
                                    window.sbi_init();
                                }, 1000);
                            }
                        });
                    observer.unobserve(entry.target);
                }
            });
        },
        { rootMargin: '0px -100px 50px 0px' }
    );
    document.querySelectorAll('[data-instagram-feed]').forEach((feed) => {
        observer.observe(feed);
    });
}

/*
// This is the template for the instagram feed as lazy loaded
<section class="layout">
    <div class="row">
        <div class="column one-whole">
            <div data-instagram-feed="1"></div>
        </div>
    </div>
</section>
 */
