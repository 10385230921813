function flipNav() {
    let els = document.querySelectorAll('.sub-menu');

    for (var i = 0, len = els.length; i < len; i++) {
        let el = els[i];
        let body = document.querySelector('body');
        let rect = el.getBoundingClientRect();

        let elLeft = rect.left;
        let elWidth = rect.width;

        if (elLeft + elWidth >= body.clientWidth) {
            el.classList.add('flip');
        } else {
            el.classList.remove('flip');
        }
    }

    console.log('%cflipNav running', 'color:#54c5b4;');
}

if (window.innerWidth > 800) {
    flipNav();
}

function tabletNav() {
    const menuItems = document.querySelectorAll(
        '.main-menu .menu-item-has-children > a'
    );

    for (var i = 0, len = menuItems.length; i < len; i++) {
        let menuItem = menuItems[i];

        let options = {
            once: true
        };

        // prettier-ignore
        menuItem.addEventListener('click', function(e) { 
            e.preventDefault(); 
        }, options);
    }

    console.log('%ctabletNav running', 'color:#54c5b4;');
}

if (
    window.innerWidth > 800 &&
    ('ontouchstart' in window || navigator.maxTouchPoints)
) {
    tabletNav();
}
