if (!!window.IntersectionObserver) {
    let observer = new IntersectionObserver(
        (entries, observer) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    if (entry.target.getAttribute('data-iframe')) {
                        const iframe = document.createElement('iframe');
                        iframe.setAttribute('src', entry.target.dataset.iframe);
                        iframe.setAttribute('title', entry.target.dataset.title);
                        entry.target.appendChild(iframe);

                        iframe.addEventListener('load', function() {
                            this.parentNode.classList.add('loaded');
                        });
                    } else {
                        const img = entry.target.querySelector('noscript');
                        if (!img) {
                            return;
                        }
                        img.outerHTML = img.textContent;
                        let imgEl = entry.target.querySelector('img');
                        imgEl.addEventListener('load', function() {
                            entry.target.classList.add('loaded');
                        });
                    }

                    observer.unobserve(entry.target);
                }
            });
        },
        { rootMargin: '0px -100px 50px 0px' }
    );
    document.querySelectorAll('.lazy').forEach((img) => {
        observer.observe(img);
    });
}
